body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#App {
  text-align: center;
}

.App-header {
  font-size: calc(10px + 2vmin);
  color: white;
}

#gallary {
  margin: 1rem;
  display: grid;
  grid-auto-rows: 3rem;
  grid-gap: 0 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
}

.photo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-modal {
    top: '50%';
    left: '50%';
    right: 'auto';
    bottom: 'auto';
    margin-right: '-50%';
    transform: 'translate(-50%; -50%)';
    padding: 0;
}

.btn-group {
    margin-top: -4px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
}

.btn-group button {
  background-color: #4CAF50; /* Green background */
  border: 1px solid green; /* Green border */
  color: white; /* White text */
  padding: 10px 24px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
  float: left; /* Float the buttons side by side */
  grid-column: span 1;
  grid-row: span 1;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}
